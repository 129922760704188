

export const DEFAULT_CONFIG = ( window as any)['DEFAULT_CONFIG'];

export enum DEFAULT_PRODUCT_STATES {
  RUNNING = 'RUNNING',
  BUILDING = 'BUILDING',
  STOPPING = 'STOPPING',
  STOPPED = 'STOPPED',
  STARTING = 'STARTING',
  HIBERNATING = 'HIBERNATING',
  HIBERNATION = 'HIBERNATION',
  TERMINATING = 'TERMINATING',
  TERMINATED = 'TERMINATED',
  ERROR = 'ERROR',
}

export const DEFAULT_PRODUCT_LOADING_STATES = [
  DEFAULT_PRODUCT_STATES.BUILDING.toLowerCase(),
  DEFAULT_PRODUCT_STATES.STOPPING.toLowerCase(),
  DEFAULT_PRODUCT_STATES.STARTING.toLowerCase(),
  DEFAULT_PRODUCT_STATES.HIBERNATING.toLowerCase(),
]


export enum DEFAULT_API_RESULT {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILED'
}

export enum DEFAULT_USER_ROLES {
  ADMINISTRATOR = 'administrator',
  USER = 'user'
}


export const DEFAULT_FORM_VALIDATION = {
  PROFILE: {
    NAME: new RegExp('^[a-zA-Z0-9- ]{1,25}$'),
    DESCRIPTION: new RegExp('^[a-zA-Z0-9-., ()/]+$'),
    URL: new RegExp('^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$'),
  },
  PROJECT: {
    NAME: new RegExp('^[a-zA-Z0-9- ]{1,25}$'),
    DESCRIPTION: new RegExp('^[a-zA-Z0-9-., ()/]+$'),
    URL: new RegExp('^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$'),
  },
  USER: {
    USERNAME: new RegExp('^[a-z ][a-z0-9- ]{1,20}[a-z ]$'),
    DISPLAY_NAME: new RegExp('^[a-zA-Z0-9 ]{1,25}$'),
    PASSWORD: new RegExp('^(?=.*[A-Z])(?=.*[!@#.$&*])(?=.*[0-9])(?=.*[a-z]).{8,64}$'),
    BIO: new RegExp('^[a-zA-Z0-9-., ()/]+$'),
    EMAIL: new RegExp('^[a-zA-Z0-9][a-zA-Z0-9\\-_\\.\\+]+@[a-zA-Z0-9\\-\\.]+\\.[a-zA-Z]+$'),
    PHONE_NUMBER: new RegExp('^[0-9+]{8,14}$'),
    NAME: new RegExp('^[a-z0-9A-Z- ]{1,25}$'),
  },
  GROUP: {
    NAME: new RegExp('^[a-zA-Z0-9- ]{1,25}$'),
    DESCRIPTION: new RegExp('^[a-zA-Z0-9-., ()/]+$'),
  },
  PRODUCT: {
    NAME: new RegExp('^[a-zA-Z0-9- ]{1,25}$'),
    USERNAME: new RegExp('^[a-z ]{2,15}$'),
    DESCRIPTION: new RegExp('^[a-zA-Z0-9-., ()/]+$'),
  },
  NOTE: {

  },
  VAULT: {
    TITLE: new RegExp('(.|\\s)*\\S(.|\\s)*'),
    PASSWORD: new RegExp('(.|\\s)*\\S(.|\\s)*'),
    URL: new RegExp('^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$'),
    USERNAME: new RegExp('(.|\\s)*\\S(.|\\s)*'),
  }
};

export const enum DEFAULT_WEB_SOCKET_ACTIONS {
  PRODUCT_LIST = 'product_list',
  PRODUCT_NOTIFICATION = 'product_notification',
  PING = 'ping',
  PONG = 'pong',
  DASHBOARD_CONNECT = 'dashboard_connect',
  DASHBOARD_DISCONNECT = 'dashboard_disconnect',
}



export const DEFAULT_NATIONALITIES: string[] = [ 
  'Afghan', 'Albanian', 'Algerian', 'American', 'Andorran', 'Angolan', 'Antiguans', 'Argentinean', 
  'Armenian', 'Australian', 'Austrian', 'Azerbaijani', 'Bahamian', 'Bahraini', 'Bangladeshi', 
  'Barbadian', 'Barbudans', 'Batswana', 'Belarusian', 'Belgian', 'Belizean', 'Beninese', 
  'Bhutanese', 'Bolivian', 'Bosnian', 'Brazilian', 'British', 'Bruneian', 'Bulgarian', 'Burkinabe', 
  'Burmese', 'Burundian', 'Cambodian', 'Cameroonian', 'Canadian', 'Cape Verdean', 'Central African', 
  'Chadian', 'Chilean', 'Chinese', 'Colombian', 'Comoran', 'Congolese', 'Costa Rican', 'Croatian', 
  'Cuban', 'Cypriot', 'Czech', 'Danish', 'Djibouti', 'Dominican', 'Dutch', 'East Timorese', 'Ecuadorean', 
  'Egyptian', 'Emirian', 'Equatorial Guinean', 'Eritrean', 'Estonian', 'Ethiopian', 'Fijian', 'Filipino', 
  'Finnish', 'French', 'Gabonese', 'Gambian', 'Georgian', 'German', 'Ghanaian', 'Greek', 'Grenadian', 
  'Guatemalan' , 'Guinea-Bissauan', 'Guinean', 'Guyanese', 'Haitian', 'Herzegovinian', 'Honduran', 
  'Hungarian', 'I-Kiribati', 'Icelander', 'Indian', 'Indonesian', 'Iranian', 'Iraqi', 'Irish', 'Israeli', 
  'Italian', 'Ivorian', 'Jamaican', 'Japanese', 'Jordanian', 'Kazakhstani', 'Kenyan', 'Kittian and Nevisian', 
  'Kuwaiti', 'Kyrgyz', 'Laotian', 'Latvian', 'Lebanese', 'Liberian', 'Libyan', 'Liechtensteiner', 'Lithuanian', 
  'Luxembourger', 'Macedonian', 'Malagasy', 'Malawian', 'Malaysian', 'Maldivian', 'Malian', 'Maltese', 
  'Marshallese', 'Mauritanian', 'Mauritian', 'Mexican', 'Micronesian', 'Moldovan', 'Monacan', 'Mongolian', 
  'Moroccan', 'Mosotho', 'Motswana', 'Mozambican', 'Namibian', 'Nauruan', 'Nepalese', 'New Zealander', 
  'Ni,Vanuatu', 'Nicaraguan', 'Nigerian', 'Nigerien', 'North Korean', 'Northern Irish', 'Norwegian', 'Omani', 
  'Pakistani', 'Palauan', 'Panamanian', 'Papua New Guinean', 'Paraguayan', 'Peruvian', 'Polish', 'Portuguese', 
  'Qatari', 'Romanian', 'Russian', 'Rwandan', 'Saint Lucian', 'Salvadoran', 'Samoan', 'San Marinese', 
  'Sao Tomean', 'Saudi', 'Scottish', 'Senegalese', 'Serbian', 'Seychellois', 'Sierra Leonean', 'Singaporean', 
  'Slovakian', 'Slovenian', 'Solomon Islander', 'Somali', 'South African', 'South Korean', 'Spanish', 
  'Sri Lankan', 'Sudanese', 'Surinamer', 'Swazi', 'Swedish', 'Swiss', 'Syrian', 'Taiwanese', 'Tajik', 'Tanzanian', 
  'Thai', 'Togolese', 'Tongan', 'Trinidadian/Tobagonian', 'Tunisian', 'Turkish', 'Tuvaluan', 'Ugandan', 
  'Ukrainian', 'Uruguayan', 'Uzbekistani', 'Venezuelan', 'Vietnamese', 'Welsh', 'Yemenite', 'Zambian', 'Zimbabwean'
]

export enum DEFAULT_AUTH_FLOWS {
  EMAIL_ONLY = 'CUSTOM_AUTH',
  EMAIL_PASSWORD = 'USER_SRP_AUTH'
}


export const DEFAULT_READABLE_PRODUCT_TYPES: any = {
  'vm': {
    'NAME': 'Desktop'
  },
  'browser': {
    'NAME': 'Browser'
  },
  'chat': {
    'NAME': 'Chat'
  },
  'vtc': {
    'NAME': 'Video'
  }
}

export const DEFAULT_RESOURCE_TYPES = {
  PRODUCT: {
    API_KEY: 'product'
  },
  PROFILE: {
    API_KEY: 'profile'
  },
  PROJECT: {
    API_KEY: 'project'
  }
}

export const DEFAULT_PRODUCT_TYPES = {
  BROWSER: {
    API_KEY: 'browser'
  },
  COMMUNICATE: {
    API_KEY: 'communicate'
  },
  DESKTOP: {
    API_KEY: 'vm'
  },
  CHAT: {
    API_KEY: 'chat'
  },
  VTC: {
    API_KEY: 'vtc'
  },
}

export const DEFAULT_API_METHODS = {
  PUT: 'PUT',
  POST: 'POST',
  PATCH: 'PATCH',
  GET: 'GET',
  DELETE: 'DELETE',
}

export const DEFAULT_AWS_LOCATIONS: any = {
  'eu-west-2': 'LONDON',
  'eu-central-1': 'FRANKFURT',
  'ap-southeast-2': 'SYDNEY',
  'us-east-2': 'OHIO',
  'eu-north-1': 'STOCKHOLM'
}

export const AL_DEFAULT_AWS_LOCATIONS: any = {
  'us-east-2': 'OHIO',
  'us-east-1': 'N.VIRGINIA',
  'us-west-1': 'N.CALIFORNIA',
  'us-west-2': 'OREGON',
  'af-south-1': 'CAPE-TOWN',
  'ap-east-1': 'HONG KONG',
  'ap-south-2': 'HYDERABAD',
  'ap-southeast-3': 'JAKARTA',
  'ap-southeast-4': 'MELBOURNE',
  'ap-south-1': 'MUMBAI',
  'ap-northeast-3': 'OSAKA',
  'ap-northeast-2': 'SEOUL',
  'ap-southeast-1': 'SINGAPORE',
  'ap-southeast-2': 'SYDNEY',
  'ap-northeast-1': 'TOKYO',
  'ca-central-1': 'CENTRAL',
  'eu-central-1': 'FRANKFURT',
  'eu-west-1': 'IRELAND',
  'eu-west-2': 'LONDON',
  'eu-south-1': 'MILAN',
  'eu-west-3': 'PARIS',
  'eu-south-2': 'SPAIN',
  'eu-north-1': 'STOCKHOLM',
  'eu-central-2': 'ZURICH',
  'il-central-1': 'TEL-AVIV',
  'me-south-1': 'BAHRAIN',
  'me-central-1': 'UAE',
  'sa-east-1': 'SAO-PAULO',
  'undefined': 'UNDEFINED',
}

export const regionToLocationMap: { [key: string]: string } = {
  'us-east-2': 'US East (Ohio)',
  'us-east-1': 'US East (N. Virginia)',
  'us-west-1': 'US West (N. California)',
  'us-west-2': 'US West (Oregon)',
  'af-south-1': 'Africa (Cape Town)',
  'ap-east-1': 'Asia Pacific (Hong Kong)',
  'ap-south-2': 'Asia Pacific (Hyderabad)',
  'ap-southeast-3': 'Asia Pacific (Jakarta)',
  'ap-southeast-4': 'Asia Pacific (Melbourne)',
  'ap-south-1': 'Asia Pacific (Mumbai)',
  'ap-northeast-3': 'Asia Pacific (Osaka)',
  'ap-northeast-2': 'Asia Pacific (Seoul)',
  'ap-southeast-1': 'Asia Pacific (Singapore)',
  'ap-southeast-2': 'Asia Pacific (Sydney)',
  'ap-northeast-1': 'Asia Pacific (Tokyo)',
  'ca-central-1': 'Canada (Central)',
  'eu-central-1': 'Europe (Frankfurt)',
  'eu-west-1': 'Europe (Ireland)',
  'eu-west-2': 'Europe (London)',
  'eu-south-1': 'Europe (Milan)',
  'eu-west-3': 'Europe (Paris)',
  'eu-south-2': 'Europe (Spain)',
  'eu-north-1': 'Europe (Stockholm)',
  'eu-central-2': 'Europe (Zurich)',
  'il-central-1': 'Israel (Tel Aviv)',
  'me-south-1': 'Middle East (Bahrain)',
  'me-central-1': 'Middle East (UAE)',
  'sa-east-1': 'South America (Sao Paulo)',
  'undefined': 'undefined',
};

export const locationToRegionMap: { [key: string]: string } = {
  'US East (Ohio)': 'us-east-2',
  'US East (N. Virginia)': 'us-east-1',
  'US West (N. California)': 'us-west-1',
  'US West (Oregon)': 'us-west-2',
  'Africa (Cape Town)': 'af-south-1',
  'Asia Pacific (Hong Kong)': 'ap-east-1',
  'Asia Pacific (Hyderabad)': 'ap-south-2',
  'Asia Pacific (Jakarta)': 'ap-southeast-3',
  'Asia Pacific (Melbourne)': 'ap-southeast-4',
  'Asia Pacific (Mumbai)': 'ap-south-1',
  'Asia Pacific (Osaka)': 'ap-northeast-3',
  'Asia Pacific (Seoul)': 'ap-northeast-2',
  'Asia Pacific (Singapore)': 'ap-southeast-1',
  'Asia Pacific (Sydney)': 'ap-southeast-2',
  'Asia Pacific (Tokyo)': 'ap-northeast-1',
  'Canada (Central)': 'ca-central-1',
  'Europe (Frankfurt)': 'eu-central-1',
  'Europe (Ireland)': 'eu-west-1',
  'Europe (London)': 'eu-west-2',
  'Europe (Milan)': 'eu-south-1',
  'Europe (Paris)': 'eu-west-3',
  'Europe (Spain)': 'eu-south-2',
  'Europe (Stockholm)': 'eu-north-1',
  'Europe (Zurich)': 'eu-central-2',
  'Israel (Tel Aviv)': 'il-central-1',
  'Middle East (Bahrain)': 'me-south-1',
  'Middle East (UAE)': 'me-central-1',
  'South America (Sao Paulo)': 'sa-east-1',
  'undefined': 'undefined',
};

export const osNameMap: { [key: string]: string } = {
  'Microsoft Windows': 'windows',
  'Windows OSINT': 'windows',
  'Ubuntu': 'ubuntu',
  'Kali': 'kali',
  'ParrotOS': 'parrotos',
  'ReactOS': 'reactos',
  'BlackArch Linux': 'blackarch',
  'SIFT Workstation': 'ubuntu',
  'Mandiant': 'windows',
  'Commando': 'windows',
  'Jitsi': 'jitsi',
  'Android': 'android',
  'undefined': 'undefined', 
};

export const sizeNameMap: { [key: string]: string } = {
  'Nano Instance': 'nano',
  'Micro Instance': 'micro',
  'Small Instance': 'small',
  'Small': 'small',
  'Medium Instance': 'medium',
  'Medium': 'medium',
  'Large Instance': 'large',
  'Large': 'large',
  'Extra Large': 'xlarge',
  'Extra Extra Large': 'xxlarge',
  'Pixel 7 Pro': 'pixel-7-pro',
  'Pixel 8 Pro': 'pixel-8-pro',
  'undefined': 'undefined',
};

export const storageNameMap: { [key: string]: string } = {
  '20GB Storage': '20',
  '30GB Storage': '30',
  '40GB Storage': '40',
  '50GB Storage': '50',
  '80GB Storage': '80',
  '160GB Storage': '160',
  '320GB Storage': '320',
  '6400GB Storage': '640',
  '1280GB Storage': '1280',
};

export const varientMap: { [key: string]: string } = {
  'Microsoft Windows': 'standard',
  'Windows OSINT': 'osint',
  'Mandiant': 'mandiant',
  'Commando': 'commando',
  'Standard Commando': 'commando',
  'Commando OS': 'commando',
  'Kali': 'standard',
  'Standard OS': 'standard',
  'Osint OS': 'osint',
  'Standard Kali': 'standard',
  'Standard Windows': 'standard',
  'Standard Jitsi': 'standard',
  'Standard Mandiant': 'commando',
  'Ubuntu': 'standard',
  'ParrotOS': 'standard',
  'ReactOS': 'standard',
  'BlackArch Linux': 'standard',
  'SIFT Workstation': 'sift',
  'Android Version 14': 'android-34-ext8',
  'Android Version 13': 'android-33-ext4',
  'Jitsi': 'standard',
};

export const timeNameMap: { [key: string]: number } = {
  'One Hour': 60,
  'Two Hours': 120,
  'Three Hours': 180,
  'Four Hours': 240,
  'Five Hours': 300,
  'Ten Hours': 600,
  '24 Hours': 1440,
  'Perpetual': 0,
  'undefined': 1,
};

export const DEFAULT_FORM_NAME_FIELDS  : string[] = ['firstName', 'middleName', 'lastName']
import React, { useState, useEffect } from 'react';


export enum AppEnvironment {
  Production,
  Test,
}

export interface IAppConfig {
  ENV: AppEnvironment;
  GLOBALS: any;
}

const defaultConfig: IAppConfig = {
  GLOBALS: {},
  ENV: AppEnvironment.Test,
}

type Props = {
  children?: React.ReactNode
}

export const AppConfigContext = React.createContext(defaultConfig)

// export const AppIsTesting = ({ children }: Props) => {
//   const { ENV }: IAppConfig = useContext(AppConfigContext)

//   return <>{authStatus === AuthStatus.SignedIn ? children : null}</>
// }

// export const EnsureSignedIn = ({ children }: Props) => {
//   const { authStatus }: IAuth = useContext(AuthContext);
//   const location = useLocation();
//   const navigate = useNavigate()

//   // Add basket state to router
//   // Add ability to store and retreive from local storage
//   // Add a 'Basket Provider Wrapper'

//   return <>{
//     authStatus === AuthStatus.SignedIn ? 
//     children : 
//     // navigate("/signin", { state: {from: location.pathname}})
//     <Navigate 
//       to="/authenticate" 
//       state={{
//         from: location.pathname,
//         currentBasket: {}
//       }} 
//       replace 
//     />
//   }</>
// }

// export const AuthIsNotSignedIn = ({ children }: Props) => {
//   const { authStatus }: IAuth = useContext(AuthContext)

//   return <>{authStatus === AuthStatus.SignedOut ? children : null}</>
// }

const getAppEnv = () : AppEnvironment => {
  let appConfigEnv: AppEnvironment;
  switch(process.env.NODE_ENV){
  case 'test': 
    appConfigEnv = AppEnvironment.Test;
    break;

  default:
    appConfigEnv = AppEnvironment.Production;
    break;
  }

  return appConfigEnv;
}

export const AuthProvider = ({ children }: Props) => {
  const [appEnv, _] = useState(getAppEnv);

  useEffect(() => {
    
  }, [appEnv])

  const state: IAppConfig = {
    ENV: appEnv,
    GLOBALS: {}
  }

  return <AppConfigContext.Provider value={state}>{children}</AppConfigContext.Provider>
}


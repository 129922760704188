import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';


import { variables, helpers } from './..';
import { amplifyCognito, api } from './../services';

export enum AuthStatus {
  Loading,
  SignedIn,
  SignedOut,
}



export interface IAuth {
  sessionInfo?: { username?: string; email?: string; sub?: string; accessToken?: string; refreshToken?: string },
  userProfile?: any
  attrInfo?: any
  authStatus?: AuthStatus
  signInEmailOnly?: any
  signInUsernamePassword?: any
  signUpWithEmail?: any
  signOut?: any
  verifyCode?: any
  getSession?: any
  refreshSessionInfo?: any
  sendCode?: any
  forgotPassword?: any
  changePassword?: any
  getAttributes?: any
  setAttribute?: any
  getApiSessionTokens?: any
  getAmplifyCurrentAthenticatedUser?: any,
  authFlow: variables.DEFAULT_AUTH_FLOWS.EMAIL_ONLY | variables.DEFAULT_AUTH_FLOWS.EMAIL_PASSWORD,
  // userKeys?: bsgCrypto.CryptoData
}

const defaultState: IAuth = {
  sessionInfo: {},
  authStatus: AuthStatus.Loading,
  authFlow: variables.DEFAULT_CONFIG['AUTHENTICATION_FLOW'],
}

type Props = {
  children?: React.ReactNode
}

export const AuthContext = React.createContext(defaultState)

export const AuthIsSignedIn = ({ children }: Props) => {
  const { authStatus }: IAuth = useContext(AuthContext)

  return <>{authStatus === AuthStatus.SignedIn ? children : null}</>
}

export const EnsureSignedIn = ({ children }: Props) => {
  const { authStatus }: IAuth = useContext(AuthContext);
  const location = useLocation();
  // const navigate = useNavigate()

  // Add basket state to router
  // Add ability to store and retreive from local storage
  // Add a 'Basket Provider Wrapper'
  return <>{
    authStatus === AuthStatus.SignedIn ? 
      children : 
    // navigate("/signin", { state: {from: location.pathname}})
      <Navigate 
        to="/authenticate" 
        state={{
          from: location.pathname,
          currentBasket: {}
        }} 
        replace 
      />
  }</>
}

export const EnsureAdminSignedIn = ({ children }: Props) => {
  const { authStatus, userProfile }: IAuth = useContext(AuthContext);
  const location = useLocation();
  // const navigate = useNavigate()

  // Add basket state to router
  // Add ability to store and retreive from local storage
  // Add a 'Basket Provider Wrapper'
  return <>{
    authStatus === AuthStatus.SignedIn && userProfile.role === variables.DEFAULT_USER_ROLES.ADMINISTRATOR ? 
      children : 
    // navigate("/signin", { state: {from: location.pathname}})
      <Navigate 
        to="/authenticate" 
        state={{
          from: location.pathname,
          currentBasket: {}
        }} 
        replace 
      />
  }</>
}



export const AuthIsNotSignedIn = ({ children }: Props) => {
  const { authStatus }: IAuth = useContext(AuthContext)

  return <>{authStatus === AuthStatus.SignedOut ? children : null}</>
}

export const AuthProvider = ({ children }: Props) => {
  const [authStatus, setAuthStatus] = useState(AuthStatus.Loading)
  const [sessionInfo, setSessionInfo] = useState<any>({})
  const [userProfile, setUserProfile] = useState<any>({})
  const [attrInfo, setAttrInfo] = useState([])
  // const [userKeys, setUserKeys] = useState<bsgCrypto.CryptoData|undefined>(undefined)

  const navigate = useNavigate();
  const location = useLocation();


  // helpers.useInterval(() => {
  //   refreshSessionInfo();

  // }, 1000 * 60 * 4);
  // // Refresh Tokens every 4 min interval

  // helpers.useInterval(async () => {
  //   try {
  //     const user = await getAmplifyCurrentAthenticatedUser();
  //   } catch (error) {
  //     navigate("/authenticate")
  //   }

  // }, 1000 * 10);

  // const getUserKeys = async () => {
  //   const session: any = await getSession();
  //   const userKeys = await amplifyCognito.getDeserialisedUserKeys({username: session.idToken.payload["cognito:username"]});
  //   setUserKeys(userKeys);
  // }

  const getSessionInfo = async () => {
    try {
      const session: any = await getSession();
      const currentAuthenticatedUser: any =  await getAmplifyCurrentAthenticatedUser();
      const currentUserAttributesResponse: any = await getProfileAttributes(currentAuthenticatedUser['attributes']['sub']);
      const currentUserRole: any = await getCurrentUserRole();

      setSessionInfo({
        accessToken: session.accessToken.jwtToken,
        refreshToken: session.refreshToken.token,
        idToken: session.idToken.jwtToken
      })
      
      setUserProfile({
        authToken: session.idToken.jwtToken,
        username: currentAuthenticatedUser['username'], // session.idToken.payload["cognito:username"],
        attributes: {
          ...currentAuthenticatedUser['attributes'],
          firstName: currentUserAttributesResponse['name']['first'],
          lastName: currentUserAttributesResponse['name']['last'],
          middleName: currentUserAttributesResponse['name']['middle'],
          displayName: currentUserAttributesResponse['display_name'],
          phone: currentUserAttributesResponse['phone'],
        },
        role: currentUserRole
      })
      
    
      const attr: any = await getAttributes()
      setAttrInfo(attr)
      setAuthStatus(AuthStatus.SignedIn)
    } catch (err) {
      setAuthStatus(AuthStatus.SignedOut)

      // navigate("/")
    }
  };

  useEffect(() => {

    getSessionInfo();
    checkFromSignInRedirect();
    
  }, [setAuthStatus, authStatus])

  const checkFromSignInRedirect = () => {
    if (location.pathname === '/authenticate' && authStatus === AuthStatus.SignedIn){
      if (location && location.state && location.state.from){
        navigate(location.state.from);
      }
    } 
  }

  const getCurrentUserRole = async() => {
    const usersData = await api.getUsers({});
    const cuid: any =  await getAmplifyCurrentAthenticatedUser()
    return usersData.body && usersData.body.find((u: any) => u.id ===  cuid['attributes']['sub'])['role'];
  }

  const getProfileAttributes = async (userSub: string) => {
    try {
      const userAtrributesResponse = await api.getUserAttributes({
        body: {
          id: userSub
        }
      });

      if ( userAtrributesResponse.status === variables.DEFAULT_API_RESULT.SUCCESS){
        
        return userAtrributesResponse.body;
      } else {

        /**
         * TO-DO:
         * - DO some error pop-up here for eg.
         */
        helpers.logToOutput('User Profile Attributes: Err: ', userAtrributesResponse.errorMessage);
      }
    } catch (err) {
      throw err
    }
    
      
    
  }

  const checkFromSignOutRedirect = () => {
    // if (location.pathname === '/signin' && authStatus === AuthStatus.SignedIn){
    //   if (location.state.from){
    //     navigate(location.state.from);
    //   }
    // } 
    navigate('/')
  }

  if (authStatus === AuthStatus.Loading) {
    return null
  }

  

  const signInEmailOnly = async(email: string, password?: string, newPassword?: string, userMfaCode?: string, setMfaSetupCode?: string) => {
    const setStatusToTrue = () => {
      setAuthStatus(AuthStatus.SignedIn);
    }
    
    try {
      const responseUser = await amplifyCognito.signInEmailOnly(setStatusToTrue, email, password, newPassword, userMfaCode, setMfaSetupCode);
      return responseUser;
      
    } catch (err) {
      helpers.logToOutput('Error during authentication or key gen', err)
    }
  }

  const signInUsernamePassword = async(username: string, password?: string, newPassword?: string, userMfaCode?: string, setMfaSetupCode?: string) => {
    try {
      await amplifyCognito.signInWithUsernamePassword(username, password, newPassword, userMfaCode, setMfaSetupCode);
      setAuthStatus(AuthStatus.SignedIn);
    } catch (err) {
      setAuthStatus(AuthStatus.SignedOut)
      throw err
    }
  }

  const signUpWithEmail = async(email: string, setupVerifyCode?: string, username?: string, password?: string) => {
    return await amplifyCognito.signUpUserWithEmail(email, setupVerifyCode, username, password)
  }

  const signOut = async() => {
    await amplifyCognito.signOut();

    setAuthStatus(AuthStatus.SignedOut);
    setSessionInfo({})
    setUserProfile({})
    setAttrInfo([])

    window.localStorage.setItem('accessToken', '');
    window.localStorage.setItem('refreshToken', '');

    // navigate("/")
    checkFromSignOutRedirect();
  }

  const verifyCode = async(username: string, code: string) => {
    try {
      await amplifyCognito.verifyCode(username, code)
    } catch (err) {
      throw err
    }
  }

  const getSession = async() => {
    try {
      const session: any = await amplifyCognito.getSession()
      window.localStorage.setItem('accessToken', `${session.accessToken.jwtToken}`)
      window.localStorage.setItem('refreshToken', `${session.refreshToken.token}`)
      return session
    } catch (err) {
      throw err
    }
  }

  const getApiSessionTokens = async() => {
    try {
      const session: any = await amplifyCognito.getSession()
      return {
        authToken: session.idToken.jwtToken,
        username: session.idToken.payload['cognito:username']
      }
    } catch (err) {
      throw err
    }
  }

  const getAttributes = async() => {
    try {
      const attr = await amplifyCognito.getAttributes()
      return attr
    } catch (err) {
      throw err
    }
  }

  const setAttribute = async(attr: any) => {
    try {
      const res = await amplifyCognito.setAttribute(attr)
      return res
    } catch (err) {
      throw err
    }
  }

  const sendCode = async(username: string) => {
    try {
      await amplifyCognito.sendCode(username)
    } catch (err) {
      throw err
    }
  }

  const forgotPassword = async(username: string, code: string, newPassword?: string) => {
    try {
      await amplifyCognito.forgotPassword(username, code, newPassword)
    } catch (err) {
      throw err
    }
  }

  const changePassword = async(oldPassword: string, newPassword: string) => {
    try {
      await amplifyCognito.changePassword(oldPassword, newPassword)
    } catch (err) {
      throw err
    }
  }

  const refreshSessionInfo = async() => {
    try {
      const session: any = await getSession()

      setSessionInfo({
        accessToken: session.accessToken.jwtToken,
        refreshToken: session.refreshToken.token,
      })
      
      setUserProfile({
        authToken: session.idToken.jwtToken,
        username: session.idToken.payload['cognito:username'] 
      })
      
      window.localStorage.setItem('accessToken', `${session.accessToken.jwtToken}`)
      window.localStorage.setItem('refreshToken', `${session.refreshToken.token}`)
      // await setAttribute({ Name: 'website', Value: 'https://kuroweb.bsg-dev.com' })
      const attr: any = await getAttributes()
      setAttrInfo(attr)
      setAuthStatus(AuthStatus.SignedIn)
    } catch (err) {
      setAuthStatus(AuthStatus.SignedOut)

      // navigate("/")
    }
  }

  const getAmplifyCurrentAthenticatedUser = async() => {
    try {
      return await amplifyCognito.getAmplifyCurrentAthenticatedUser()
    } catch (err) {
      throw err
    }
  }

  const state: IAuth = {
    authStatus,
    sessionInfo,
    attrInfo,
    userProfile,
    authFlow: defaultState.authFlow,
    // userKeys,
    signUpWithEmail,
    signInEmailOnly,
    signInUsernamePassword,
    signOut,
    verifyCode,
    getSession,
    refreshSessionInfo,
    sendCode,
    forgotPassword,
    changePassword,
    getAttributes,
    setAttribute,
    getApiSessionTokens,
    getAmplifyCurrentAthenticatedUser,
  }

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>
}


import {useContext } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Dropdown, OverlayTrigger, Row, Button } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';

import {getCurrentTime} from '../../../utilities/helpers'

// types
import { Notification } from './types';

type NotificationProps = {
  notifications: Notification[];
  isNewNotifications: boolean;
  setIsNewNotifications: (value: boolean) => void;
  clearNotifications: any
};

const NotificationDropdown = ({ notifications, isNewNotifications, clearNotifications }: NotificationProps) => {
  const currentTimeDate = getCurrentTime();

  return (
    <>
      <OverlayTrigger trigger="focus" key="navOverlayTrigger1" placement="left" overlay={
        <Dropdown className="nav-item k-z-ind-999999 k-b-shadow" show drop="start">
          <Dropdown.Menu className="p-2 k-b-shadow" renderOnMount>
            {
              notifications === undefined || notifications?.length ===0 ? (
                <Dropdown.Item className="p-2 k-min-w-225" key={0}>
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1">
                      <h6 className="fw-medium my-0 fs-13">No Notifications</h6>
                      <span className="text-muted">
                        <span className='txt-8'>{currentTimeDate}</span>
                      </span>
                    </div>
                  </div>
                </Dropdown.Item>
              ) : (
                (notifications || []).map((notification, index) => {
                  return (
                    <Dropdown.Item className="p-2 k-min-w-225" key={index.toString()}>
                      <div className="d-flex align-items-center">
                        <span className={classNames( 'bg-soft-' + notification.variant,'text-' + notification.variant,'avatar','avatar-xs','rounded icon','icon-with-bg','icon-xxs','me-3','flex-shink-0')}>
                          <FeatherIcon
                            icon={notification.icon}
                            className={classNames('icon-dual-' + notification.variant)}
                          />
                        </span>
                        <div className="flex-grow-1">
                          <Row><span className="p-0 m-0 fw-medium txt-12">{notification.text}</span></Row>
                          <Row><span className="p-0 m-0 text-muted txt-8 ">{notification.time}</span></Row>
                        </div>
                      </div>
                    </Dropdown.Item>
                  );
                })
              )
            }
            <Link to="#" className="btn btn-light btn-sm bg-light text-center fs-13 d-block mt-2" onClick={clearNotifications}>
              { notifications === undefined ? ( <> - </> ) : ( <>Clear Messages</> ) }
            </Link>
          </Dropdown.Menu>  
        </Dropdown>

      }>
        <Button variant='link' className="k-bg-trans m-0 p-0 ms-2 mt-2 k-border-none k-center-content-hor-right k-no-button">
          { notifications !== undefined && notifications?.length > 0 ? (
            <>
              <FeatherIcon size={30} strokeWidth={1} icon="bell" className="icon-dual-dark" />
              { isNewNotifications && notifications?.length > 0 &&
                <div className='notifications-active-badge'>
                  {notifications?.length}
                </div>
              }
            </>
          ) : (
            <>
              <FeatherIcon size={30} strokeWidth={1} icon="bell" className="icon-fill-light" />
            </>
          )}
        </Button>
      </OverlayTrigger>
    </>
  )
};

export default NotificationDropdown;

import { PanelResizeHandle } from 'react-resizable-panels';
import { FC } from 'react';

import Icon from './Icon';
import styles from './ResizeHandle.module.css';

interface ResizeHandleProps {
  className?: string;
  collapsed?: boolean;
  id?: string;
}

const ResizeHandle: FC<ResizeHandleProps> = ({
  className = '',
  collapsed = false,
  id,
}) => {
  return (
    <PanelResizeHandle
      className={[styles.ResizeHandleOuter, className].join(' ')}
      id={id}
    >
      <div
        className={styles.ResizeHandleInner}
        data-collapsed={collapsed || undefined}
      >
        <Icon className={styles.HorizontalIcon} type="resize-horizontal" />
        <Icon className={styles.VerticalIcon} type="resize-vertical" />
      </div>
    </PanelResizeHandle>
  );
}


export default ResizeHandle;
// import React from 'react';
// import ReactDOM from 'react-dom';

// import './i18n';

// import App from './App';
// import reportWebVitals from './reportWebVitals';

// ReactDOM.render(
//     <React.StrictMode>
//         <App />
//     </React.StrictMode>,
//     document.getElementById('root')
// );

import { createRoot } from 'react-dom/client';

import './i18n';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { variables } from 'utilities'

const productioniseCode = () => {
  if(variables.DEFAULT_CONFIG['ENVIRONMENT'] === 'PRODUCTION'){
    // Set Logs to disable
    // eslint-disable-next-line no-console
    console.log = () => {};
  }
}

const container = document.getElementById('root')

// Production config befor app run
productioniseCode();
const root = createRoot(container!)
root.render(<App/>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

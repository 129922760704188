import { Nav } from 'react-bootstrap';

const Menu = () => {
  return (
    <Nav as="ul" className="mx-auto">
      {/* <Nav.Item as="li" className="pe-3">
                <Nav.Link as={Link} to="/" className="active">
                    <div className="d-flex align-items-center">
                        <span className="icon-xxs me-1 flex-shrink-0">
                            <FeatherIcon icon="grid" className="icon-dual-primary" />
                        </span>
                        <div className="flex-grow-1">Home</div>
                    </div>
                </Nav.Link>
            </Nav.Item>

            <Nav.Item as="li" className="pe-3">
                <Nav.Link as={Link} to="/dashboard">
                    <div className="d-flex align-items-center">
                        <span className="icon-xxs me-1 flex-shrink-0">
                            <FeatherIcon icon="folder" className="icon-dual-dark" />
                        </span>
                        <div className="flex-grow-1">Dashboard</div>
                    </div>
                </Nav.Link>
            </Nav.Item>

            <Nav.Item as="li" className="pe-3">
                <Nav.Link as={Link} to="/base1">
                    <div className="d-flex align-items-center">
                        <span className="icon-xxs me-1 flex-shrink-0">
                            <FeatherIcon icon="list" className="icon-dual-dark" />
                        </span>
                        <div className="flex-grow-1">Base 1</div>
                    </div>
                </Nav.Link>
            </Nav.Item>

            <Nav.Item as="li" className="pe-3">
                <Nav.Link as={Link} to="/base2">
                    <div className="d-flex align-items-center">
                        <span className="icon-xxs me-1 flex-shrink-0">
                            <FeatherIcon icon="bar-chart-2" className="icon-dual-dark" />
                        </span>
                        <div className="flex-grow-1">Base 2</div>
                    </div>
                </Nav.Link>
            </Nav.Item>

            <Nav.Item as="li" className="pe-3">
                <Nav.Link as={Link} to="/base3">
                    <div className="d-flex align-items-center">
                        <span className="icon-xxs me-1 flex-shrink-0">
                            <FeatherIcon icon="tool" className="icon-dual-dark" />
                        </span>
                        <div className="flex-grow-1">Base 3</div>
                    </div>
                </Nav.Link>
            </Nav.Item>

            <Nav.Item as="li" className="pe-3">
                <Nav.Link as={Link} to="/checkout">
                    <div className="d-flex align-items-center">
                        <span className="icon-xxs me-1 flex-shrink-0">
                            <FeatherIcon icon="tool" className="icon-dual-dark" />
                        </span>
                        <div className="flex-grow-1">Checkout</div>
                    </div>
                </Nav.Link>
            </Nav.Item> */}
           
    </Nav>
  );
};

export default Menu;

import { useEffect } from 'react';
import AOS from 'aos';
import { ToastContainer } from 'react-toastify';

// routes
import Routes from './routes';

// Services
// import { webSocket } from './utilities/services';

// Themes
//  For Default import Theme.scss
import './assets/scss/theme.scss';
import 'react-toastify/dist/ReactToastify.css';



const App: React.FC = () => {
  useEffect(() => {
    AOS.init();

    // Connect Websocket
    // webSocket.attachWebSocket();

    // return applicationCleanup()
  }, []);

    
  // const applicationCleanup = () => {
  //   // webSocket.detachWebSocket();
  // }
  return (
    <>
      <ToastContainer
        style={{ zIndex: '1000000' }} // Above current nav z-index 
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={'light'}
      />
      <Routes />
    </>
  );
};

export default App;

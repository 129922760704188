import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import {
  PaymentElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';

import { variables, helpers } from '../..//utilities'

type StripeCheckoutProps = {
  handleCheckoutSuccess: () => void
  onCancel: () => void;
}

const StripeCheckout : React.FunctionComponent<StripeCheckoutProps> = ({ onCancel}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent && paymentIntent.status) {
      case 'succeeded':
        setMessage('Payment succeeded!');
        break;
      case 'processing':
        setMessage('Your payment is processing.');
        break;
      case 'requires_payment_method':
        setMessage('Your payment was not successful, please try again.');
        break;
      default:
        setMessage('Something went wrong.');
        break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (submitEvent: any) => {
    submitEvent.preventDefault();
    const url = 'https://' + variables.DEFAULT_CONFIG['DOMAIN_URL'] 
    const redirectUrl = url +  '/dashboard';

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const stripeResult = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        // return_url: "https://kuro.click/dashboard",
        return_url: redirectUrl,
      },
      //redirect: "if_required",
    });
    
    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if ( stripeResult.error?.type === 'card_error' || stripeResult.error?.type === 'validation_error') {
      setMessage(stripeResult.error && stripeResult.error.message!);
    } else {
      setMessage('An unexpected error occurred.');
    }

    setIsLoading(false);
  };

  const paymentElementOptions: any = {
    layout: 'tabs'
  }

  
  return (
    <form id="payment-form" >
      <PaymentElement id="payment-element" options={paymentElementOptions} />
  

      <Button variant="outline-primary" className="p-1 m-1 mt-2 me-0 float-end" disabled={isLoading || !stripe || !elements} id="submit" type={'submit'} onClick={handleSubmit}>
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : 'Pay now'}
        </span>
      </Button>

      <Button variant="outline-secondary" className="p-1 m-1 mt-2 me-0 float-end" disabled={isLoading || !stripe || !elements} id="cancel" type="button" onClick={onCancel}>
        <span id="button-cancel">Cancel</span>
      </Button>

      {/* Show any error or success messages*/}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}

export default StripeCheckout;